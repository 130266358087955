































































































































































































































































































































































































.title-gradiant {
  font-size: 50px;
  background: linear-gradient(to right, #0bb4e4, #29f366);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Montserrat-ExtraBold';
  text-transform: uppercase;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffff;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
}
.title-section {
  color: #ffff;
  font-size: 50px;
  text-transform: uppercase;
  font-family: 'Montserrat-ExtraBold';
  align-items: center;
  text-align: center;
  -webkit-text-stroke-color: black;
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.5));
}
.hr.dec-color {
  background: #ffff;
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1.5px;
  max-width: 450px;
}
.hr.dec-color2 {
  background: linear-gradient(to right, #0bb4e4, #29f366);
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1.5px;
  max-width: 500px;
}
.hr.dec-color3 {
  background: linear-gradient(to right, #0bb4e4, #29f366);
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1.5px;
  max-width: 450px;
}
input[id='cb1'] + label {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #007308;
  border-radius: 2px;
  cursor: pointer;
  margin: 5px 0 0;
  text-align: center;
}
input[id='cb1']:checked + label:after {
  position: relative;
  top: -4px;
  left: 0px;
  content: '\2714';
  font-size: 14px;
}
input[id='cb1'] {
  display: none;
}
.title {
  text-align: right;
  background: #fff0;
  padding: 0;
  font-size: 15px;
  font-weight: 700;
  color: #007308;
}
#page-content {
  padding: 0 !important;
  position: relative;
}
.btn-reward {
  margin: auto;
  max-width: 350px;
  position: relative;
  text-align: center;
}
.vm--modal {
  background: #fff0;
  padding: 0;
  max-width: 600px !important;
}
.img-reward {
  width: 100%;
}
.btn-reward button {
  background: #fff0;
  outline: none;
  border: none;
  box-shadow: none;
  padding: 0;
  width: 100%;
  margin: auto;
}
.btn-reward button img {
  width: 180px;
}
.box-title {
  width: fit-content;
  margin: auto;
  padding: 5px 25px;
  border: 2px #007308 solid;
  border-radius: 20px;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  font-style: italic;
  position: relative;
  margin-top: 20px;
}

.box-title span {
  position: relative;
  z-index: 2;
}
.box-title .light {
  position: absolute;
  width: 120%;
  bottom: 0px;
  left: -15%;
  z-index: 1;
}
.box-title .light img {
  width: 100%;
}
.table-responsive {
  margin: 0;
}
.text-white {
  color: #fff !important;
}
.line-bottom {
  display: inline-block;
  margin: 0 2px 30px;
  height: 2px;
  width: 30px;
  background: rgb(255 255 255 / 0.7);
}
.section-content {
  padding: 40px 0 25px;
  @media (max-width: 575px){
    padding: 0px 0 25px;
  }
}
.section-content .title-sub {
  font-size: 20px;
  font-weight: 900;
  color: #fff;
  line-height: 1.4;
  max-width: 700px;
  margin: 0 auto 20px;
}
.section-content .text-sub {
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
  color: #fff;
  line-height: 1.35;
  max-width: 700px;
  margin: auto;
}
.box-desc {
  width: 80%;
  max-width: max-content;
  margin: 25px auto;
  text-align: left;
}
.box-desc h3 {
  color: transparent;
  background: #007308;
  font-family: 'Orbitron', sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 900;
  text-transform: uppercase;
  -webkit-background-clip: text;
  -webkit-text-stroke: 0.5px white;
  filter: drop-shadow(0 0 3px rgb(0, 0, 0, 0.3));
}
.box-desc ul {
  list-style: none;
  margin-bottom: 0;
}
.box-desc ul li {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
}
.box-desc ul li i {
  color: #fffa94;
  font-size: 12px;
  padding-right: 10px;
}
.section-content .box {
  border: 2px #77b61d solid;
  border-radius: 10px;
  max-width: 900px;
  font-family: sans-serif;
  margin: auto;
  color: #fff;
  background: #1e2024;
  padding: 15px;
  .table-responsive {
    padding: 0px 10px 0px 0px;
  }
  @media (min-width: 1024px) {
    .table-responsive {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  @media (max-width: 767px) {
    padding: 5px;
  }
}
.section-content .box .box2 {
  border: 2px #fff solid;
  border-radius: 10px;
  max-width: 800px;
  font-family: sans-serif;
  margin: auto;
  color: #fff;
  background: #282627;
  margin: 0px auto;
  padding: 15px 30px;
}
.table-responsive {
  max-height: 500px;
  overflow: auto;
}
.text-color-yellow {
  color: #fffa94 !important;
  font-weight: 600;
}
.section-content .box .table thead > tr > th,
.section-content .box .table tbody > tr > th,
.section-content .box .table tfoot > tr > th,
.section-content .box .table thead > tr > td,
.section-content .box .table tbody > tr > td,
.section-content .box .table tfoot > tr > td,
.section-content .box .table tbody + tbody,
.section-content .box .table-bordered,
.section-content .box .table-bordered > thead > tr > th,
.section-content .box .table-bordered > tbody > tr > th,
.section-content .box .table-bordered > tfoot > tr > th,
.section-content .box .table-bordered > thead > tr > td,
.section-content .box .table-bordered > tbody > tr > td,
.section-content .box .table-bordered > tfoot > tr > td {
  border: 2px #fff0 solid !important;
  margin: 2px !important;
  vertical-align: middle;
}
.trading-section .heading {
  max-width: 600px;
  margin: auto;
  position: relative;
  text-align: center;
}
.trading-section .heading .img-light img {
  position: absolute;
  width: 90%;
  min-width: 70px;
}
.trading-section .heading .img-light img.bottom {
  bottom: -55%;
  left: -15%;
  z-index: 1;
}
.trading-section .heading .img-light img.top {
  top: -55%;
  right: -15%;
  z-index: 1;
}
.trading-section .heading img {
  width: auto;
  position: relative;
  z-index: 2;
  height: 25px;
  @media (max-width: 400px){
    width: 100%;
    height: auto;
  }
}
.section-content .box .table {
  border-collapse: inherit;
  border-spacing: 3px;
  color: #fff;
}
.section-content .box .box2 .table {
  border-spacing: 7px;
}
.section-content .box .table thead > tr > th {
  background: #5da000;
  padding: 10px 15px;
  color: #fff;
  text-align: center;
  font-weight: 900;
  font-size: 15px;
  text-transform: uppercase;
  vertical-align: middle;
}
.section-content .box .table-striped > tbody > tr {
  background: transparent;
  color: #dfdfe2;
  font-size: 15px;
  font-weight: 500;
  td {
    padding: 0;
    span {
      height: 50px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.section-content .box .table-striped > tbody > tr:nth-of-type(odd) {
  color: #b9b5b5;
  background-color: rgba(90, 84, 84, 0.288);
  font-size: 15px;
  font-weight: 500;
}
.trading-section {
  background-position: center center;
  background-size: cover;
  padding: 60px 20px 30px;
  background-repeat: no-repeat;
  @media (max-width: 575px){
    padding: 10px 20px 30px;
  }
}
.section-content .box .box2 .table th,
.section-content .box .box2 .table td {
  width: 20%;
}
.bg-trading1 {
  background-image: url('~@/assets/images/promotion/bg_trading_secion1-min.png');
}
.bg-trading2 {
  background-image: url('~@/assets/images/promotion/bg_trading_secion2-min.png');
}
.bg-trading3 {
  background-image: url('~@/assets/images/promotion/bg_trading_secion3-min.png');
}
.text-center {
  text-align: center !important;
}
.btn-group > .btn {
  float: none;
}
.justify-content-center {
  justify-content: center !important;
}
.font-medium,
.text-theme-11 {
  text-align: left;
  color: #fff;
  padding: 0 15px;
  margin-bottom: 0px;
}
.text-theme-11 {
  color: yellow;
}
.upload_images {
  width: calc(100% - 30px);
  height: 160px;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  background: #daf2fdb3 !important;
  border-radius: 10px;
  margin: 0 15px;
  margin-bottom: 2rem;
  margin-top: 0rem;
}
.widget {
  background-color: rgb(255 255 255 / 0.25);
}
.show_images {
  width: calc(100% - 30px);
  height: 200px;
  position: relative;
  padding: 20px;
  margin: 0 15px;
  text-align: center;
  background: #daf2fdb3 !important;
}

.show_images img {
  height: 100%;
  width: auto;
  display: inline-block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.show_images button {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 35px;
  background: gold;
  border: none;
  right: 0;
  padding: 5px 15px;
  bottom: 0;
  margin: auto;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.show_images:hover {
  background: #056996b3 !important;
}

.show_images:hover img {
  opacity: 0.5;
}

.show_images:hover button {
  opacity: 1;
}

.upload_file_1 {
  height: 0;
  overflow: hidden;
  width: 0;
}

.upload_file_1 + label {
  background: #f15d22;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: 'Rubik', sans-serif;
  font-size: inherit;
  font-weight: 500;
  outline: none;
  padding: 10px 49px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  width: max-content;
}

@media screen and (max-width: 575px) {
  .box-desc ul {
    padding-left: 0;
  }
}
.upload_file_1 + label.btn-2 {
  background-color: #f78b00;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 3px 4px 10px -4px rgb(0 0 0 / 0.7);
}
.upload_file_1 + label.btn-2::before {
  color: #fff;
  content: '\f382';
  font-family: 'Font Awesome 5 Pro';
  font-size: 100%;
  height: 100%;
  right: 130%;
  line-height: 3.3;
  position: absolute;
  top: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.upload_file_1 + label.btn-2:hover {
  background-color: #fbc500;
}
.upload_file_1 + label.btn-2:hover::before {
  right: 75%;
}
.icon {
  width: 80px;
  height: 80px;
  margin: auto;
  background: #c8973b;
  line-height: 80px;
  font-size: 36px;
  border-radius: 50%;
  color: #fff;
}
.mr-5 {
  margin-right: 5px !important;
}
.vm--modal {
  overflow-y: auto;
}
.profile h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
.bg-theme-11 {
  margin: 0;
  height: 47px;
  display: block;
  line-height: 0;
  padding: 10px;
  background: #3ab32a;
  color: #fff;
}
.title {
  text-align: center;
  background: #1e90ff;
  padding: 5px 0;
  font-size: 15px;
  font-weight: 700;
  color: white;
}
.col-4 {
  width: 33.33333333%;
  float: left;
}
.col-8 {
  width: 66.6666667%;
  float: left;
}
